import React from "react"
import "../../assets/index.css"
import Layout from "../../layouts/layout"
import Hero from "../../components/hero-blog"
import Postings from "../../components/postings-blog"

export default () => (
    <Layout title="Blog | Ladd Partners"> 
        <Hero></Hero>
        <Postings></Postings>
    </Layout>
)
