import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

const WrapperDiv = styled.div`
    max-width: 1110px;
    margin: 60px auto 0;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    margin-bottom: 140px;

    @media only screen and (max-width: 1024px) {
        margin-bottom: 20px;
    }

    > p {
        max-width: 1015px;
    }

    h3 {
        width: 100%;
    }
`;

const PostingsContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const MoreInfo = styled(Link)`
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #23C0F5;
    align-self: flex-end;
    width: 100%;
`

const PostingsContentDiv = styled.div`
    width: 355px;
    box-sizing: border-box;
    padding: 0 25px;
    margin-bottom: 56px;

    &:first-of-type, &:nth-of-type(n + 2) {
        padding-left: 0;
    }

    &:nth-of-type(n + 3) {
        padding-right: 0;
    }


    @media only screen and (max-width: 1140px) {
        width: 48%;
        padding: 0;
    }

    @media only screen and (max-width: 840px) {
        width: 100%;
        margin-bottom: 40px;
    }
`;

const PostContentDiv = styled.div`
    border-radius: 3px;
    box-shadow: rgba(61, 123, 178, 0.4) 0px 2px 10px 0px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;

    .content {
        padding: 30px;
    }
    a {
        width: 100%;
    }
    img {
        height: 200px;
    }

    @media only screen and (max-width: 1140px) {

        img {
            height: auto;
            width: 100%;
        }
    }
`;

const query = graphql`
    query {
        allSanityBlog(sort: {fields: [date], order: DESC}) {
            totalCount
            edges {
              node {
                id
                slug {
                    current
                }
                title
                excerpt
                date(formatString: "MMMM Do, YYYY")
                author {
                    first_name
                    last_name
                }
                thumbnail_image {
                    asset {
                      url
                    }
                  }
              }
            }
          }
      } 
`

const Postings = ({post}) => (
        <PostingsContentDiv>
            <PostContentDiv>
                <Link to={getPostUrl(post.slug.current)}><img src={post.thumbnail_image.asset.url} alt="Blog Thumbnail" className="margin-bottom-0"/></Link>
                <div className="content">
                <Link to={getPostUrl(post.slug.current)}><h3 className="margin-bottom-10">{post.title}</h3></Link>
                    <p className="margin-bottom-10"><small>{post.author.first_name} {post.author.last_name} | {post.date} </small></p>

                    <p>{post.excerpt}...</p>
                    <MoreInfo to={getPostUrl(post.slug.current)}>Read More</MoreInfo>
                </div>
            </PostContentDiv>
        </PostingsContentDiv>
)

function getPostUrl(slug) {
    return `/company/blog/${slug}`;
}
function getPostings(data) {
    const PostingsArray = [];
    data.allSanityBlog.edges.forEach(item => {
        PostingsArray.push(<Postings post={item.node} key={item.node.id}></Postings>)
    });

    if(PostingsArray.length % 3 == 2 ) {
        PostingsArray.push(<PostingsContentDiv> </PostingsContentDiv>);
    }

    return PostingsArray;
}

export default () => (
    <StaticQuery
        query={query}
        render={data => {
            return (
                <>
                <WrapperDiv id="listings">
                <PostingsContainer id={data.allSanityBlog.totalCount <= '2' ? 'less-than-two' : ''}>
                    {getPostings(data)}
                </PostingsContainer> 
                </WrapperDiv>
                </>
            )
        }}
    />
)
